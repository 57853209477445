import React, { SFC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Cta from '../../base/Cta';
import Wrapper from '../../base/Wrapper';
import {
  mqUp,
  mqDown,
  fontFamily,
  header,
  fontSize,
  color,
  spacing,
} from '../../../constants/styles';
import Pattern from '../../../images/logo-pattern.svg';

import { route } from '../../../constants/app';
interface HomeHeroProps {
  titleLight?: string;
  titleBold?: string;
  text?: string;
  titleBoldArray?: Array<string>;
  cta?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: url(${Pattern}) top right no-repeat;
  background-size: auto 70%;
  min-height: 100vh;
  padding: ${spacing.XXXL} 0;
  ${mqDown('mainNav')} {
    margin-top: -${header.heightSmall};
  }
  ${mqUp('mainNav')} {
    margin-top: -${header.heightBase};
  }
`;

const TitleLight = styled.div`
  font-family: ${fontFamily.secondaryLight};
  background: -webkit-linear-gradient(${color.clr1}, ${color.clr6});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const TitleBold = styled.div`
  font-family: ${fontFamily.secondaryBold};
  font-size: 120%;
`;

const TitleStyle = css`
  text-transform: uppercase;
  line-height: 1.25;
  font-size: ${fontSize.XL};
  ${mqUp('tablet')} {
    font-size: 5rem;
  }
`;

const Text = styled.div`
  max-width: 60rem;
  ${mqUp('smartphone')} {
    font-size: ${fontSize.L};
  }
`;

const HomeHero: SFC<HomeHeroProps> = ({
  titleLight,
  titleBold,
  titleBoldArray,
  text,
  cta,
  ...rest
}) => (
  <Container {...rest}>
    <Wrapper
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        padding-top: 10vh;
        padding-bottom: 10vh;
        text-align: center;
      `}
    >
      <div css={TitleStyle}>
        <TitleLight>{titleLight}</TitleLight>
        <TitleBold>{titleBold}</TitleBold>
      </div>
      <Text
        css={css`
          margin: ${spacing.L} auto 0;
        `}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <div
        css={css`
          margin-top: ${spacing.XL};
        `}
      >
        <Cta to={route.references}>{cta}</Cta>
      </div>
    </Wrapper>
  </Container>
);

HomeHero.defaultProps = {};

export default HomeHero;
