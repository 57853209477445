import React, { SFC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Wrapper from '../../base/Wrapper';
import Cta from '../../base/Cta';
import SkillsCard from '../../base/SkillsCard';
import {
  mqUp,
  fontFamily,
  fontSize,
  color,
  spacing,
} from '../../../constants/styles';
import { route } from '../../../constants/app';

interface HomeWeAreProps {
  titleLight?: string;
  titleBold?: string;
  text?: string;
  cards?: object;
  ctaText?: string;
}

const Container = styled.div`
  position: relative;
  padding: ${spacing.XXXXL} 0 16rem;
`;

const Title = styled.div`
  text-transform: uppercase;
  line-height: 1.25;
  letter-spacing: 1px;
  ${mqUp('smartphone')} {
    font-size: ${fontSize.L};
  }
  ${mqUp('tablet')} {
    font-size: ${fontSize.XXL};
  }
  &::after {
    content: '';
    display: inline-block;
    height: 4px;
    width: 8rem;
    background: ${color.clr1};
    border-radius: 2px;
  }
`;

const TitleLight = styled.div`
  font-family: ${fontFamily.secondaryLight};
  color: ${color.clr4};
`;

const TitleBold = styled.div`
  font-family: ${fontFamily.secondaryBold};
  font-size: 160%;
  color: ${color.light};
`;

const Text = styled.p`
  max-width: 60rem;
  color: ${color.light};
  opacity: 0.85;
  ${mqUp('smartphone')} {
    font-size: ${fontSize.L};
  }
`;

const HomeWeAre: SFC<HomeWeAreProps> = ({
  titleLight,
  titleBold,
  text,
  cards,
  ctaText,
  ...rest
}) => (
  <Container {...rest}>
    <Wrapper
      css={css`
        position: relative;
        z-index: 9;
        text-align: center;
      `}
    >
      <Title>
        {titleLight && <TitleLight>{titleLight}</TitleLight>}
        {titleBold && <TitleBold>{titleBold}</TitleBold>}
      </Title>
      {text && (
        <Text
          css={css`
            margin: ${spacing.L} auto;
          `}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
      <SkillsCard
        css={css`
          margin-top: ${spacing.XXL};
          margin-bottom: ${spacing.XXL};
        `}
        data={cards}
      />
      <div
        css={css`
          text-align: center;
          margin-top: ${spacing.XXL};
        `}
      >
        {ctaText &&
          <Cta theme="neutral" to={route.expertise}>{ctaText}</Cta>
        }
      </div>
    </Wrapper>
    <div
      css={css`
        z-index: 8;
        position: absolute;
        top: -8rem;
        left: -30rem;
        right: -30rem;
        bottom: -8rem;
        transform: rotate(-12deg);
        background-image: linear-gradient(
          77.5deg,
          rgb(106, 55, 145) 0%,
          rgb(74, 48, 125) 100%
        );
        transform: rotate(-12.5deg);
      `}
    />
    <div
      css={css`
        position: absolute;
        top: 2rem;
        left: -30rem;
        right: -30rem;
        bottom: 50%;
        transform: rotate(12deg);
        transform: rotate(-12deg);
        background-image: linear-gradient(-265deg, #6ae7b6 0%, #72dbf6 89%);
        transform: rotate(12.5deg);
      `}
    />
  </Container>
);

HomeWeAre.defaultProps = {};

export default HomeWeAre;
