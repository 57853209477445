import React, { SFC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Wrapper from '../../base/Wrapper';
import Cta from '../../base/Cta';
import ProjectCard from '../../base/ProjectCard';
import {
  spacing,
  mqUp,
  color,
  fontFamily,
  fontSize,
} from '../../../constants/styles';
import { route } from '../../../constants/app';

interface HomeProjectsProps {
  titleLight?: string;
  titleBold?: string;
  ctaText?: string;
  data: Array<{
    to: string;
    title: string;
    content: { html: string };
    thumb: string;
    show_on_homepage: 'true' | 'false';
    tags: { html: string };
  }>;
}

const Container = styled.div`
  position: relative;
  padding: ${spacing.XXXXL} 0 16rem;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${spacing.L};
  ${mqUp('tablet')} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mqUp('desktop')} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const TitleLight = styled.div`
  font-family: ${fontFamily.secondaryLight};
  color: ${color.clr1};
`;

const TitleBold = styled.div`
  font-family: ${fontFamily.secondaryBold};
  font-size: 160%;
  color: ${color.neutralDark};
`;

const Title = styled.div`
  text-align: center;
  text-transform: uppercase;
  line-height: 1.25;
  letter-spacing: 1px;
  margin-bottom: ${spacing.XXL};
  ${mqUp('smartphone')} {
    font-size: ${fontSize.L};
  }
  ${mqUp('tablet')} {
    font-size: ${fontSize.XXL};
  }
  &::after {
    content: '';
    display: inline-block;
    height: 4px;
    width: 8rem;
    background: ${color.clr1};
    border-radius: 2px;
  }
`;

const HomeProjects: SFC<HomeProjectsProps> = ({
  data,
  titleLight,
  titleBold,
  ctaText,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <Wrapper
        css={css`
          position: relative;
        `}
      >
        <Title>
          <TitleLight>{titleLight}</TitleLight>
          <TitleBold>{titleBold}</TitleBold>
        </Title>
        <Grid>
          {data.map(item => {
            if (item.show_on_homepage === 'true') {
              return (
                <ProjectCard
                  key={item.title}
                  title={item.title}
                  thumb={item.thumb}
                  content={item.content.html}
                  tags={item.tags.html}
                />
              );
            }
          })}
        </Grid>
        <div
          css={css`
            text-align: center;
            margin-top: ${spacing.XXL};
          `}
        >
          <Cta theme="neutral" to={route.references}>
            {ctaText}
          </Cta>
        </div>
      </Wrapper>
    </Container>
  );
};

export default HomeProjects;
