import React, { SFC } from 'react';
import { IconInterface } from '../../../appInterface';
import { color } from '../../../constants/styles';

const IconRecruitment: SFC<IconInterface> = ({ size, color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 62 65"
    {...rest}
  >
    <path
      fill={color}
      d="M56.2 22.5h-.5c2.2-1.2 3.7-3.6 3.7-6.3 0-4-3.2-7.2-7.2-7.2S45 12.2 45 16.2c0 2.9 1.8 5.5 4.3 6.6-2 .7-3.4 2.6-3.4 4.8v7.3h-3.7v-5.2c0-4-3.2-7.2-7.2-7.2h-.3c2.2-1.2 3.7-3.6 3.7-6.3 0-4-3.2-7.2-7.2-7.2S24 12.2 24 16.2c0 2.7 1.5 5.1 3.7 6.3h-1c-4 0-7.2 3.2-7.2 7.2v5.2h-4.1v-7.3c0-2.3-1.6-4.3-3.7-4.9 2.5-1.1 4.2-3.6 4.2-6.5 0-4-3.2-7.2-7.2-7.2s-7.2 3.2-7.2 7.2c0 2.7 1.5 5.1 3.7 6.3h-.1c-2.8 0-5.1 2.3-5.1 5.1v15.5c0 2.1 1.2 3.8 3 4.7V64h2V48.3h10.5v14.5c0 .6.4 1 1 1h6.2c.6 0 1-.4 1-1V45.5H30v16h-5v2h10.3v-2H32v-16h5.6v17.3c0 .6.4 1 1 1h6.2c.6 0 1-.4 1-1V48.3h9.7v15.8h2v-16c2.2-.6 3.8-2.6 3.8-4.9V27.6c0-2.8-2.3-5.1-5.1-5.1zM26 16.2c0-2.9 2.3-5.2 5.2-5.2s5.2 2.3 5.2 5.2-2.3 5.2-5.2 5.2-5.2-2.3-5.2-5.2zm21 0c0-2.9 2.3-5.2 5.2-5.2s5.2 2.3 5.2 5.2-2.3 5.2-5.2 5.2-5.2-2.3-5.2-5.2zm-14.2 8.3l-1.7 4.4-1.7-4.4h3.4zm-11.3 5.2c0-2.9 2.3-5.2 5.2-5.2h.6l2.9 7.6c.1.4.5.6.9.6s.8-.3.9-.6l2.9-7.6h.1c2.9 0 5.2 2.3 5.2 5.2v5.2h-4.7c-.6 0-1 .4-1 1V39h-7.7v-3.1c0-.6-.4-1-1-1h-4.3v-5.2zm-18-13.5c0-2.9 2.3-5.2 5.2-5.2s5.2 2.3 5.2 5.2-2.3 5.2-5.2 5.2-5.2-2.3-5.2-5.2zm18.2 45.6h-4.2V47.3c0-.6-.4-1-1-1H5.1c-1.7 0-3.1-1.4-3.1-3.1V27.6c0-1.7 1.4-3.1 3.1-3.1h5.2c1.7 0 3.1 1.4 3.1 3.1v8.3c0 .6.4 1 1 1h10.4v3.2H10.3v-9.3h-2v10.3c0 .6.4 1 1 1h9.3c1.7 0 3.1 1.4 3.1 3.1v16.6zm16.2-18.3H23.4c-.2-.5-.4-1-.8-1.4h3.2c.6 0 1-.4 1-1V41h7.7v.1c0 .6.4 1 1 1h3.2c-.4.4-.6.9-.8 1.4zm21.4-.3c0 1.7-1.4 3.1-3.1 3.1H44.8c-.6 0-1 .4-1 1v14.5h-4.2V45.2c0-1.7 1.4-3.1 3.1-3.1H52c.6 0 1-.4 1-1V30.7h-2V40H36.5v-3.2h10.4c.6 0 1-.4 1-1v-8.3c0-1.7 1.4-3.1 3.1-3.1h5.2c1.7 0 3.1 1.4 3.1 3.1v15.7z"
    />
    <path
      fill={color}
      d="M11.5 7.2h4.2v2.1c0 .4.2.7.6.9.1.1.3.1.4.1.2 0 .4-.1.6-.2l3.9-2.9H26c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1H11.5c-.6 0-1 .4-1 1v5.2c0 .5.4 1 1 1zm1-5.2H25v3.2h-4.2c-.2 0-.4.1-.6.2l-2.5 1.9V6.2c0-.6-.4-1-1-1h-4.2V2zm23 1h2.1v2h-2.1zM40 3h2.1v2H40zm4.5 0h2.1v2h-2.1z"
    />
  </svg>
);

IconRecruitment.defaultProps = {
  size: 60,
  color: color.clr2,
};

export default IconRecruitment;
