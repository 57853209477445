import React, { SFC } from 'react';
import Tilt from 'react-vanilla-tilt';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { route } from '../../constants/app';
import AppLink from './AppLink';
import IconDesign from './icons/IconDesign';
import IconDev from './icons/IconDev';
import IconArchi from './icons/IconArchi';
import IconStrategy from './icons/IconStrategy';
import IconRecruitment from './icons/IconRecruitment';
import {
  color,
  radius,
  spacing,
  fontFamily,
  mqUp,
} from '../../constants/styles';

interface SkillsCardItem {
  title: string;
  theme: 'design' | 'dev' | 'strategy' | 'transfer';
  url: string;
}

interface SkillsCardProps {
  data: [SkillsCardItem];
}

const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${spacing.S};
  grid-auto-flow: row dense;
  ${mqUp('tablet')} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mqUp('desktop')} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Card = styled.li`
  position: relative;
  height: calc(100% - ${spacing.M});
  text-align: center;
  > * {
    height: calc(100% - ${spacing.M});
  }
`;

const Item = styled(AppLink)`
  display: block;
  height: 100%;
  z-index: 1;
  background: ${color.neutralDark};
  color: ${color.light};
  border-radius: ${radius.S};
  padding: ${spacing.XL} ${spacing.L};
  margin: ${spacing.M};
  text-decoration: none;
  &::after {
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    top: ${spacing.M};
    left: ${spacing.M};
    right: ${spacing.M};
    bottom: 0;
    background: ${color.neutralDark};
    box-shadow: 0 0 ${spacing.M} ${color.neutralDark};
    opacity: 0.65;
  }

  &:focus {
    color: ${color.light};
  }
`;

export const Themes = {
  design: css`
    ${Item} {
      &:hover {
        color: ${color.clr1};
      }
    }
  `,
  transfer: css`
    ${Item} {
      &:hover {
        color: ${color.clr2};
      }
    }
  `,

  dev: css`
    ${Item} {
      &:hover {
        color: ${color.clr3};
      }
    }
  `,
  archi: css`
    ${Item} {
      &:hover {
        color: ${color.clr4};
      }
    }
  `,
  strategy: css`
    ${Item} {
      &:hover {
        color: ${color.clr5};
      }
    }
  `,
};

const IconSkills = props => {
  switch (props.data) {
    case 'design':
      return <IconDesign />;
    case 'dev':
      return <IconDev />;
    case 'archi':
      return <IconArchi />;
    case 'strategy':
      return <IconStrategy />;
    case 'transfer':
      return <IconRecruitment />;
    default:
      return null;
  }
};

const SkillsCard: SFC<SkillsCardProps> = ({ data, ...rest }) => (
  <Grid {...rest}>
    {data.map(item => (
      <Card
        key={item.title}
        css={css`
          ${Themes[item.theme]}
        `}
      >
        <Tilt
          options={{ max: 15 }}
          css={css`
            width: 100% !important;
            background: transparent !important;
            box-shadow: none !important;
            padding: 0 !important;
            margin: 0 !important;
          `}
        >
          <Item to={route.expertise + '#' + item.theme}>
            <IconSkills data={item.theme} />
            <h2
              css={css`
                font-family: ${fontFamily.secondaryMedium};
                margin-top: ${spacing.M};
              `}
            >
              {item.title}
            </h2>
          </Item>
        </Tilt>
      </Card>
    ))}
  </Grid>
);

SkillsCard.defaultProps = {};

export default SkillsCard;
