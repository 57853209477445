import React, { SFC } from 'react';
import styled from '@emotion/styled';
import TextLoop from 'react-text-loop';
import { css } from '@emotion/core';
import Wrapper from '../../base/Wrapper';
import CardText from '../../base/CardText';
import {
  mqUp,
  fontFamily,
  fontSize,
  color,
  spacing,
} from '../../../constants/styles';

interface HomeYouAreCardsContent {
  html: string;
}
interface HomeYouAreCards {
  title: string;
  content: HomeYouAreCardsContent;
}

interface HomeYouAreProps {
  titleLight: string;
  titleBold: string;
  text: string;
  cards: [HomeYouAreCards];
}

const Container = styled.div`
  position: relative;
  z-index: 13;
  padding: 14rem 0 ${spacing.XXXXL};
`;

const Title = styled.div`
  text-transform: uppercase;
  line-height: 1.25;
  letter-spacing: 1px;
  ${mqUp('smartphone')} {
    font-size: ${fontSize.L};
  }
  ${mqUp('tablet')} {
    font-size: ${fontSize.XXL};
  }
  &::after {
    content: '';
    display: inline-block;
    height: 4px;
    width: 8rem;
    background: ${color.clr6};
    border-radius: 2px;
  }
`;

const TitleLight = styled.div`
  font-family: ${fontFamily.secondaryLight};
  color: ${color.clr3};
`;

const TitleBold = styled.div`
  text-align: center;
  width: 100%;
  font-family: ${fontFamily.secondaryBold};
  font-size: 160%;
  color: ${color.light};
`;

const Text = styled.p`
  max-width: 60rem;
  color: ${color.light};
  opacity: 0.85;
  ${mqUp('smartphone')} {
    font-size: ${fontSize.L};
  }
`;

const Grid = styled.div`
  padding-top: 6rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${spacing.L};
  grid-auto-flow: row dense;
  ${mqUp('tablet')} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const HomeYouAre: SFC<HomeYouAreProps> = ({
  titleLight,
  titleBold,
  text,
  cards,
  ...rest
}) => (
  <Container {...rest}>
    <Wrapper
      css={css`
        position: relative;
        z-index: 9;
        text-align: center;
      `}
    >
      <Title>
        <TitleLight>{titleLight}</TitleLight>
        <TitleBold>{titleBold}</TitleBold>
      </Title>
      <Text
        css={css`
          margin: ${spacing.L} auto;
        `}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <Grid>
        {cards.map(item => (
          <CardText
            key={item.title}
            title={item.title}
            text={item.content.html}
          />
        ))}
      </Grid>
    </Wrapper>
  </Container>
);

HomeYouAre.defaultProps = {};

export default HomeYouAre;
