import React, { SFC } from 'react';
import { IconInterface } from '../../../appInterface';
import { color } from '../../../constants/styles';

const IconDev: SFC<IconInterface> = ({ size, color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 61 61"
    {...rest}
  >
    <path
      d="M14.15 61H1.05c-.6 0-1-.4-1-1V46.8c0-5.7 3.4-9.8 9.1-12.6 3.2-1.6 6.9-2.6 10.7-3.2-2.2-2-3.5-4.7-3.5-7.6V11.2C16.25 5 22.15 0 29.25 0h2.4c7.1 0 13 5 13 11.2v12.2c0 2.9-1.3 5.6-3.6 7.7 3.7.6 7.4 1.6 10.7 3.2 5.8 2.8 9.2 6.9 9.2 12.5V59c0 1.1-.9 1.9-1.9 1.9h-12.3l-32.6.1c.1 0 .1 0 0 0zm-.9-2v-7.2h-3.1c-.6 0-1-.4-1-1v-6.1h2v5.1h2.1V37.6c0-.6.4-1 1-1h32.5c.6 0 1 .4 1 1v12.2h2.1v-5.1h2v6.1c0 .6-.4 1-1 1h-3.1V59h11.2V46.8c0-4.8-2.9-8.3-8-10.7-3.6-1.7-7.9-2.8-12.2-3.3-.5.3-1 .5-1.5.7-1.7.7-3.7 1.1-5.6 1.1h-2.4c-2 0-3.9-.4-5.6-1.1-.5-.2-1-.5-1.5-.7-4.2.5-8.5 1.5-12.2 3.3-5.1 2.5-8 6-8 10.8V59h11.3zm2 0h30.5V38.6h-30.5V59zm7.5-28.2s.1 0 .1.1c.5.3 1 .6 1.5.8 1.5.6 3.2.9 4.8.9h2.4c1.7 0 3.3-.3 4.9-.9.5-.2 1-.5 1.5-.8 0 0 .1 0 .1-.1h.1c2.8-1.7 4.5-4.4 4.5-7.4V11.2c0-5-4.9-9.2-11-9.2h-2.4c-6.1 0-11 4.2-11 9.2v12.2c0 2.9 1.7 5.6 4.5 7.4 0-.1 0 0 0 0zm3.4-11.2l-1.5 1.4-4-4c-.4-.4-.4-1 0-1.4l4.1-4.1 1.4 1.4-3.4 3.4 3.4 3.3zm8.7-6.7l1.4-1.4 4.1 4.1c.4.4.4 1 0 1.4l-4.1 4-1.4-1.4 3.4-3.4-3.4-3.3zm-2.2-4.1l1.8.8-5.1 12.2-1.8-.8 5.1-12.2zm.9 39c0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1 0 1.7 1.4 3.1 3.1 3.1 1.7-.1 3.1-1.4 3.1-3.1zm2 0c0 2.8-2.3 5.1-5.1 5.1s-5.1-2.3-5.1-5.1 2.3-5.1 5.1-5.1 5.1 2.3 5.1 5.1zM45.75 0v2h-3.1V0h3.1zm5.1 2V0h10.2v2h-10.2zm-1 18.3v-2h10.2v2h-10.2zm-3.1 6.1v-2h8.1v2h-8.1zm-1-18.3v-2h9.2v2h-9.2zm9.1 6.1v-2h6.1v2h-6.1zm4.1-6.1v-2h2v2h-2zm0 18.3v-2h2v2h-2zm-12.2-12.2v-2h3.1v2h-3.1z"
      fill={color}
    />
  </svg>
);

IconDev.defaultProps = {
  size: 60,
  color: color.clr3,
};

export default IconDev;
