import React, { SFC } from 'react';
import { IconInterface } from '../../../appInterface';
import { color } from '../../../constants/styles';

const IconArchi: SFC<IconInterface> = ({ size, color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 63 62"
    {...rest}
  >
    <path
      d="M54.7 46.5c-.4 0-.9 0-1.3.1l-8-15.6 5.9-11.3c1 .5 2.2.8 3.4.8 4.3 0 7.8-3.5 7.8-7.8S59 4.8 54.7 4.8 47 8.3 47 12.6c0 2.4 1.1 4.5 2.7 5.9l-5.4 10.3-7.8-15.1c1.7-1.4 2.7-3.5 2.7-5.9 0-4.3-3.5-7.8-7.8-7.8s-7.8 3.5-7.8 7.8v.3l-10 5c-1.3-.9-2.8-1.5-4.5-1.5-4.3 0-7.8 3.5-7.8 7.8s3.5 7.8 7.8 7.8c1.6 0 3-.5 4.3-1.3l4.6 4-8.5 16.7c-.4-.1-.8-.1-1.3-.1-4.3 0-7.8 3.5-7.8 7.8S4 62 8.3 62c3.6 0 6.6-2.5 7.5-5.8h31.4c.9 3.3 3.9 5.8 7.5 5.8 4.3 0 7.8-3.5 7.8-7.8s-3.5-7.7-7.8-7.7zM49 12.6c0-3.2 2.6-5.8 5.8-5.8s5.8 2.6 5.8 5.8-2.6 5.8-5.8 5.8-5.8-2.6-5.8-5.8zm2.5 34.6c-2.7 1.2-4.5 3.9-4.6 7H16c0-1.2-.3-2.4-.8-3.4l11.6-8c1.4 2.2 3.8 3.7 6.6 3.7 4.2 0 7.6-3.3 7.8-7.5l3.1-5.9 7.2 14.1zM30.6 15.5l.9 15.8c-1.9.5-3.6 1.8-4.6 3.4l-6.2-5.3 7.5-14.6c.7.3 1.5.6 2.4.7zm8.6 23.2c0 3.2-2.6 5.8-5.8 5.8-3.2 0-5.8-2.6-5.8-5.8s2.6-5.8 5.8-5.8c3.2.1 5.8 2.7 5.8 5.8zm4-7.8l-2.5 4.9c-1.2-2.8-4-4.8-7.3-4.8l-.9-15.5c.8-.1 1.6-.3 2.3-.7l8.4 16.1zM31.5 2c3.2 0 5.8 2.6 5.8 5.8 0 3.2-2.6 5.8-5.8 5.8-3.2 0-5.8-2.6-5.8-5.8 0-3.2 2.6-5.8 5.8-5.8zm-28 17.4c0-3.2 2.6-5.8 5.8-5.8 3.2 0 5.8 2.6 5.8 5.8 0 3.2-2.6 5.8-5.8 5.8-3.2 0-5.8-2.6-5.8-5.8zM15 24.6c1.2-1.4 2-3.2 2-5.2 0-1.8-.6-3.5-1.7-4.8l8.8-4.4c.4 1.4 1.3 2.6 2.3 3.5L19.1 28 15 24.6zm4.7 6.6l6.3 5.4c-.2.7-.3 1.4-.3 2.2 0 .8.1 1.5.3 2.2l-11.9 8.2c-.7-.8-1.6-1.5-2.6-2l8.2-16zM8.3 60c-3.2 0-5.8-2.6-5.8-5.8s2.6-5.8 5.8-5.8c3.2 0 5.8 2.6 5.8 5.8S11.5 60 8.3 60zm46.4 0c-3.2 0-5.8-2.6-5.8-5.8s2.6-5.8 5.8-5.8 5.8 2.6 5.8 5.8-2.6 5.8-5.8 5.8z"
      fill={color}
    />
  </svg>
);

IconArchi.defaultProps = {
  size: 60,
  color: color.clr4,
};

export default IconArchi;
