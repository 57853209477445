import React, { SFC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Wrapper from '../../base/Wrapper';
import {
  mqUp,
  mqDown,
  spacing,
  color,
  fontFamily,
  fontSize,
  radius,
  lineHeight,
} from '../../../constants/styles';

interface HomeDataItem {
  data: number;
  legend: string;
}

interface HomeDataProps {
  title: string;
  data: [HomeDataItem];
}

const Container = styled.div`
  position: relative;
  padding: 0 0 ${spacing.XXXXL} 0;
`;

const Grid = styled.ul`
  padding: ${spacing.M};
  background: ${color.light};
  border-radius: ${radius.XS};
  ${mqUp('tablet')} {
    display: flex;
  }
`;

const Shadow = styled.div`
  position: relative;
  transform: rotate(12.5deg) skew(12.5deg);
  &::after {
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    top: ${spacing.M};
    left: ${spacing.M};
    right: ${spacing.M};
    bottom: 0;
    box-shadow: 0 0 3rem ${color.neutralDark};
    opacity: 0.5;
  }
`;

const GridItem = styled.li`
  ${mqDown('tablet')} {
    padding: ${spacing.L};
    &:not(:last-child) {
      border-bottom: 1px solid ${color.neutralLighter};
      padding-bottom: ${spacing.XL};
    }
  }
  ${mqUp('tablet')} {
    flex-grow: 1;
    padding: ${spacing.L};
    &:not(:last-child) {
      border-right: 1px solid ${color.neutralLighter};
    }
  }
`;

const Box = styled.div`
  text-align: center;
  color: ${color.clr6};
  transform: rotate(-12.5deg) skew(-12.5deg);
`;

const Data = styled.div`
  font-family: ${fontFamily.secondaryLight};
  font-size: ${fontSize.XXXXL};
  line-height: 1;
  background: -webkit-linear-gradient(${color.clr1}, ${color.clr6});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${mqUp('tablet')} {
    font-size: 8rem;
  }
`;

const Legend = styled.div`
  font-family: ${fontFamily.secondaryBold};
  font-size: ${fontSize.XXS};
  text-transform: uppercase;
  line-height: ${lineHeight.XS};
  margin-top: ${spacing.M};
`;

const Ribbon = styled.div`
  position: absolute;
  z-index: 12;
  top: -5.7rem;
  left: 50%;
  padding: ${spacing.S} ${spacing.L};
  background: ${color.clr1};
  text-transform: uppercase;
  font-family: ${fontFamily.secondaryBold};
  font-size: ${fontSize.XXS};
  letter-spacing: 1px;
  color: ${color.light};
  transform: rotate(-12.5deg) skew(-12.5deg) translateX(-50%);

  ${mqUp('tablet')} {
    top: -4.7rem;
  }
`;

const HomeData: SFC<HomeDataProps> = ({ data, title, ...rest }) => (
  <Container {...rest}>
    <Wrapper
      size="M"
      css={css`
        position: relative;
        z-index: 13;
        text-align: center;
      `}
    >
      <Ribbon>{title}</Ribbon>
      <Shadow>
        <Grid>
          {data.map(item => (
            <GridItem key={item.legend}>
              <Box>
                <Data>{item.data}</Data>
                <Legend>{item.legend}</Legend>
              </Box>
            </GridItem>
          ))}
        </Grid>
      </Shadow>
    </Wrapper>
    <div
      css={css`
        z-index: 12;
        position: absolute;
        top: 0;
        left: -30rem;
        right: -30rem;
        bottom: -60rem;
        background-image: linear-gradient(
          77.5deg,
          rgb(245, 78, 129) 0%,
          rgb(74, 48, 125) 100%
        );
        transform: rotate(-12.5deg);
      `}
    />
    <div
      css={css`
        z-index: 11;
        position: absolute;
        top: 0.6rem;
        left: -30rem;
        right: -30rem;
        bottom: -40rem;
        background-image: linear-gradient(-82deg, #eb9f76 4%, #f5ca85 97%);
        transform: rotate(12.5deg);
      `}
    />
  </Container>
);

HomeData.defaultProps = {};

export default HomeData;
