import React, { SFC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
  color,
  radius,
  spacing,
  mqUp,
  fontSize,
  fontFamily,
} from '../../constants/styles';

interface CardTextProps {
  title: string;
  text: string;
}

const Container = styled.div`
  position: relative;
  &::after {
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    top: ${spacing.M};
    left: ${spacing.M};
    right: ${spacing.M};
    bottom: 0;
    box-shadow: 0 0 3rem ${color.neutralDark};
    opacity: 0.15;
  }
`;

const Inner = styled.div`
  height: 100%;
  padding: ${spacing.L};
  text-align: left;
  background: ${color.light};
  border-radius: ${radius.S};
`;

const Title = styled.div`
  font-size: ${fontSize.L};
  font-family: ${fontFamily.secondaryMedium};
  margin-bottom: ${spacing.M};
`;

const Text = styled.div`
  color: ${color.neutral};
`;

const CardText: SFC<CardTextProps> = ({ title, text, ...rest }) => (
  <Container {...rest}>
    <Inner>
      {title && <Title>{title}</Title>}
      {text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
    </Inner>
  </Container>
);

export default CardText;
