import React, { SFC } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Image from '../base/Image';
import { Link } from 'gatsby';
import {
  color,
  spacing,
  radius,
  fontSize,
  fontFamily,
  lineHeight,
} from '../../constants/styles';

interface ProjectCardProps {
  title: string;
  content: string;
  thumb: string;
  tags: string;
}

const Container = styled.div`
  position: relative;
  background: ${color.light};
  border-radius: ${radius.S};
  color: ${color.neutral};
  &::after {
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    top: ${spacing.M};
    left: ${spacing.M};
    right: ${spacing.M};
    bottom: 0;
    background: ${color.light};
    box-shadow: 0 0 3rem ${color.neutralDark};
    opacity: 0.15;
  }
`;

const ImagePlaceholder = styled.div`
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.neutralDark};
  font-size: ${fontSize.XXL};
  font-family: ${fontFamily.secondaryMedium};
`;

const Body = styled.div`
  padding: 0 ${spacing.L} ${spacing.L};
`;

const Content = styled.div`
  font-size: ${fontSize.XS};
  line-height: ${lineHeight.XL};
  > * + * {
    margin-top: ${spacing.S};
  }
`;

const Tags = styled.div`
  font-size: ${fontSize.XS};
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: ${spacing.S} -${spacing.S} -${spacing.S};
  }
  li {
    margin: 0.8rem;
    padding: 0.4rem 0.8rem;
    border-radius: ${radius.S};
    background: ${color.neutralLighter};
    &:before {
      content: '#';
    }
  }
`;

const ProjectCard: SFC<ProjectCardProps> = ({
  thumb,
  title,
  content,
  tags,
  ...rest
}) => {
  console.log(thumb.url)
  return (
    <Container {...rest}>
      <h2
        css={css`
          margin: ${spacing.M} auto;
          max-width: 20rem;
        `}
      >
        {thumb.url && (
          <Image data={thumb} alt={title || ''} />
        )}
        {!thumb.url && (
          <ImagePlaceholder>{title}</ImagePlaceholder>
        )}
      </h2>
      
      <Body>
        {content && <Content dangerouslySetInnerHTML={{ __html: content }} />}
        {tags && <Tags dangerouslySetInnerHTML={{ __html: tags }} />}
      </Body>
    </Container>
  );
};

export default ProjectCard;
