import React, { SFC } from 'react';
import { IconInterface } from '../../../appInterface';
import { color } from '../../../constants/styles';

const IconStrategy: SFC<IconInterface> = ({ size, color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 63 62"
    {...rest}
  >
    <path
      fill={color}
      d="M33.9 23.7L13.6 9.8V7c0-1.3-.7-2.4-1.9-2.8-1.1-.5-2.4-.2-3.3.7L6.2 7c-2.5 2.5-3.9 5.9-3.9 9.5v39.3H1.2c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1h29c.6 0 1-.4 1-1v-4.1c0-.6-.4-1-1-1h-1.1v-3.4l-6.4-19.1c2.4 1.1 5.2.9 7.6-.5 2.5-1.5 4-4.1 4-7.1v-1.3c0-.3-.1-.6-.4-.8zM4.3 16.5c0-3 1.2-5.9 3.3-8.1l2.1-2.1c.6-.5 1.1-.3 1.3-.3.2.1.7.3.7 1v2.9l-2 2c-1.4 1.4-2.1 3.2-2.1 5.1v34.7H4.3V16.5zM29.2 60h-27v-2.1h27V60zM4.3 55.9v-2.1h22.9v2.1H4.3zm28-30.1c0 2.2-1.1 4.2-3 5.4-1.9 1.1-4.2 1.2-6.1.2L16.3 27l-1.1 1.7 4.9 3.1 6.6 19.9H9.5V17c0-1.4.5-2.7 1.5-3.7l1.7-1.7L32.3 25v.8z"
    />
    <path
      fill={color}
      d="M13.7 17.6h2v2.1h-2zm48.4-9.9L58 .5c-.4-.6-1.4-.6-1.7 0l-4.1 7.2c-.2.3-.2.7 0 1 .2.3.5.5.9.5h8.3c.4 0 .7-.2.9-.5 0-.3 0-.6-.2-1zm-7.4-.5L57.1 3l2.4 4.2h-4.8zM39.5 24.8c2.8 0 5.1-2.3 5.1-5.1s-2.3-5.1-5.1-5.1-5.1 2.3-5.1 5.1 2.3 5.1 5.1 5.1zm0-8.3c1.7 0 3.1 1.4 3.1 3.1 0 1.7-1.4 3.1-3.1 3.1s-3.1-1.4-3.1-3.1c0-1.7 1.4-3.1 3.1-3.1zm9.4 17.6c0 2.8 2.3 5.1 5.1 5.1s5.1-2.3 5.1-5.1S56.8 29 54 29s-5.1 2.3-5.1 5.1zM54 31c1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1-3.1-1.4-3.1-3.1S52.3 31 54 31zM39.5 44.5c-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1 5.1-2.3 5.1-5.1-2.2-5.1-5.1-5.1zm0 8.3c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.3 3.1-3.1 3.1zM27.8 10l2.9-2.9 2.9 2.9 1.5-1.4-3-2.9 3-3-1.5-1.4-2.9 2.9-2.9-2.9-1.4 1.4 2.9 3-2.9 2.9zm32.7 43.1L57.6 56l-2.9-2.9-1.4 1.4 2.9 2.9-2.9 2.9 1.4 1.4 2.9-2.9 2.9 2.9 1.5-1.4-3-2.9 3-2.9z"
    />
    <path
      fill={color}
      d="M53.7 20.8c.2-.2.3-.4.3-.7s-.1-.5-.3-.7L40.2 6l-1.4 1.4 12.7 12.7-12.7 12.8c-.2.2-.3.4-.3.7s.1.5.3.7l12.9 12.9 1.4-1.4-12.2-12.2 12.8-12.8z"
    />
  </svg>
);

IconStrategy.defaultProps = {
  size: 60,
  color: color.clr5,
};

export default IconStrategy;
