import React, { SFC } from 'react';
import { IconInterface } from '../../../appInterface';
import { color } from '../../../constants/styles';

const IconDesign: SFC<IconInterface> = ({ size, color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 60 60"
    {...rest}
  >
    <path
      fill={color}
      d="M59 27h-1.2c-1.1-10.4-8-19.2-17.5-23h13.9c.4 1.2 1.5 2 2.8 2 1.7 0 3-1.3 3-3s-1.3-3-3-3c-1.3 0-2.4.8-2.8 2H33V1c0-.6-.4-1-1-1h-4c-.6 0-1 .4-1 1v1H5.8C5.4.8 4.3 0 3 0 1.3 0 0 1.3 0 3s1.3 3 3 3c1.3 0 2.4-.8 2.8-2h13.9C10.2 7.8 3.3 16.6 2.2 27H1c-.6 0-1 .4-1 1v4c0 .6.4 1 1 1h4c.6 0 1-.4 1-1v-4c0-.6-.4-1-1-1h-.8C5.6 15.1 15.1 5.6 27 4.2V5c0 .6.4 1 1 1h4c.6 0 1-.4 1-1v-.8C44.9 5.6 54.4 15.1 55.8 27H55c-.6 0-1 .4-1 1v4c0 .6.4 1 1 1h4c.6 0 1-.4 1-1v-4c0-.6-.4-1-1-1zM4 31H2v-2h2v2zM57 2c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zM3 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm28 0h-2V2h2v2zm27 27h-2v-2h2v2z"
    />
    <path
      fill={color}
      d="M43 29c-3.3 0-6 2.7-6 6v3h-2.8l.8-3.8v-.4l-.9-4.6 6.7-8.6c.3-.4.3-.9 0-1.3l-10-11c-.4-.4-1.1-.4-1.5 0l-10 11c-.3.4-.3.9 0 1.3l6.7 8.6-1 4.6v.4l.8 3.8c-1.6.1-2.8 1.4-2.8 3 0 .8.3 1.5.8 2-.5.5-.8 1.2-.8 2s.3 1.5.8 2c-.5.5-.8 1.2-.8 2s.3 1.5.8 2c-.5.5-.8 1.2-.8 2 0 1.7 1.3 3 3 3v4h2v-4h6c1.7 0 3-1.3 3-3 0-.8-.3-1.5-.8-2 .5-.5.8-1.2.8-2s-.3-1.5-.8-2c.5-.5.8-1.2.8-2s-.3-1.5-.8-2c.5-.5.8-1.2.8-2 0-.4-.1-.7-.2-1H38c.6 0 1-.4 1-1v-4c0-1.9 1.3-3.4 3-3.9V60h2V30c0-.6-.4-1-1-1zm-15.2 9l-.8-4 .8-4h4.4l.8 4-.8 4h-4.4zM31 21c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1zm-2-9.4v6.6c-1.2.4-2 1.5-2 2.8 0 1.7 1.3 3 3 3s3-1.3 3-3c0-1.3-.8-2.4-2-2.8v-6.6l7.7 8.5-6.2 7.9h-5l-6.2-7.9 7.7-8.5zM35 45c0 .6-.4 1-1 1h-8c-.6 0-1-.4-1-1s.4-1 1-1h8c.6 0 1 .4 1 1zm0 4c0 .6-.4 1-1 1h-8c-.6 0-1-.4-1-1s.4-1 1-1h8c.6 0 1 .4 1 1zm-1 5h-8c-.6 0-1-.4-1-1s.4-1 1-1h8c.6 0 1 .4 1 1s-.4 1-1 1zm1-13c0 .6-.4 1-1 1h-8c-.6 0-1-.4-1-1s.4-1 1-1h8c.6 0 1 .4 1 1zm-23 5h2v2h-2zm-4 4h2v2H8zm0-4h2v2H8zm0-4h2v2H8zm38 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2zm0 4h2v2h-2z"
    />
  </svg>
);

IconDesign.defaultProps = {
  size: 60,
  color: color.clr1,
};

export default IconDesign;
