import React from 'react';
import { graphql } from 'gatsby';
import App from '../components/layout/App';
import HomeHero from '../components/page/home/HomeHero';
import HomeWeAre from '../components/page/home/HomeWeAre';
import HomeYouAre from '../components/page/home/HomeYouAre';
import HomeData from '../components/page/home/HomeData';
import HomeProjects from '../components/page/home/HomeProjects';

const IndexPage = props => {
  const DATA = props.data.content.data;
  const SKILLS = props.data.skills.data;
  const WORKS = props.data.works.data;
  return (
    <App
      title={DATA.seo_title}
      description={DATA.seo_description}
      image={DATA.seo_image.url}
    >
      <HomeHero
        titleLight={DATA.hero_title_light}
        titleBold={DATA.hero_title_bold}
        text={DATA.hero_content.html}
        cta={DATA.hero_link_text}
      />
      <HomeWeAre
        titleLight={DATA.section_1_title_light}
        titleBold={DATA.section_1_title_bold}
        text={DATA.section_1_content.html}
        cards={SKILLS.skills}
        ctaText={SKILLS.skills_cta}
      />
      <HomeData
        title={DATA.section_1_ribbon_title}
        data={DATA.section_1_data}
      />
      <HomeYouAre
        titleLight={DATA.section_2_title_small}
        titleBold={DATA.section_2_title_bold}
        text={DATA.section_2_content.html}
        cards={DATA.section_2_cards}
      />
      <HomeProjects
        titleLight={WORKS.title_light}
        titleBold={WORKS.title_bold}
        ctaText={WORKS.more_reference_cta}
        data={WORKS.card}
      />
    </App>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    content: prismicHomepage {
      data {
        seo_title
        seo_description
        seo_image {
          url
        }
        hero_title_light
        hero_title_bold
        hero_content {
          html
        }
        hero_link_text
        section_1_title_light
        section_1_title_bold
        section_1_content {
          html
        }
        section_1_ribbon_title
        section_1_data {
          data
          legend
        }
        section_2_title_small
        section_2_title_bold
        section_2_content {
          html
        }
        section_2_cards {
          title
          content {
            html
          }
        }
      }
    }
    skills: prismicSkills {
      data {
        skills_cta
        skills {
          title
          theme
        }
      }
    }
    works: prismicWorks {
      data {
        title_light
        title_bold
        more_reference_cta
        card {
          title
          content {
            html
          }
          show_on_homepage
          tags {
            html
          }
          thumb {
            url
          }
        }
      }
    }
  }
`;
